
*{
  box-sizing: border-box;
}
#root {
  height: 100%;
  margin:0;
  width: 100vw;
}
.App {
  text-align: center;
  width: 100% !important;
  display: flex;
  height: 100%;
  min-height: 100%;
  flex-direction: column;
  max-width: 100vw;
  /* grid-column: 1fr auto-fill; */
}
html, body{
  width: 100%;
  height: 100%;
  margin: 0;
}
.drinkIndividualList{
  padding-top: 30px;;
}

.episodeList{
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  padding-top: 0;
  justify-items: center;
  column-gap: 50px;
  row-gap: 25px;
}

.EpisodeLoading{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.episodeListCard{
  /* padding-top: 10px; */
  margin: 0;
}
.EpisodeListImage {
  height: 200px !important;
  width: auto;
  background-size: cover;
  
}
.ui.card, .ui.cards>.card{
  min-height: 380px !important;
}
#base{
  flex-grow: 1;
}
.NavBackground{
  width: 100% !important;
  background-color: black;
  text-align: left;
  min-width: 100vw;
  /* height: 12vh; */
}
.NavBackground img{
  width: 340px;
}
.homeBase{
  height: 100%;
  background-color: black;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

svg{
  width: 100px;
  height: 100px;
  margin: 20px;
  /* display:inline-block; */
}
.EpisodeBase{
  width: 80%;
  margin: auto;
  padding-bottom: 50px;
}

.EpisodeBase h1{
 font-size: 5rem;
}
.EpisodeSegmentHead{
  background-color: black !important;
  color: white;
}
.EpisodeDivider{
  height:20px;
}

.ui.horizontal.divider:after, .ui.horizontal.divider:before {
  background-color: white;

}

.ui.divider.inverted:before{
  /* padding-right: 30px !important; */
  right: 10px;
}
.ui.divider.inverted:after{
  /* padding-right: 30px !important; */
  left: 10px;
}
.ui.divider.inverted, .ui.divider.inverted:after, .ui.divider.inverted:before {
  border-width: 3px;
  border-top-color: rgba(184, 184, 184, 0.15)!important;
  border-left-color: rgba(84, 184, 184, 0.15)!important;
  border-bottom-color: rgba(255, 255, 255, 0.568)!important;
  border-right-color: rgba(8, 8, 8, 0.993)!important;
  height: 15px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 600px) {
  .episodeList{
    margin: 0;
    /* padding-left:10%; */
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* background-color: red; */
  }
  .EpisodeBase h1{
    font-size: 2rem;
   }
  .EpisodeBase{
    width: 90%;
    margin: auto;
    padding-bottom: 50px;
  }

  .NoVideoAvailable{
    width: 100%;
  }
  
  .EpisodeBase h1{
    font-size: 3rem;
   }
}


@media only screen and (min-width: 600px) { 

  .episodeList{
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
    padding-top: 0;
    justify-items: center;
    column-gap: 50px;
    row-gap: 25px;
  }
  .NoVideoAvailable{
    width: 100%;
  }
  .EpisodeBase h1{
    font-size: 3rem;
   }
  
}

@media only screen and (min-width: 992px) {
 
.episodeList{
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  padding-top: 0;
  justify-items: center;
  column-gap: 50px;
  row-gap: 25px;
}
.NoVideoAvailable{
  width: 100%;
}

}
